.ProcessStep-Container {
    cursor: pointer;
}

.ProcessStep-Title-Container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: 75px;
}

.ProcessStep-Title {
    font-size: 30px;
    font-weight: 600;
    font-family: SpaceGrotesk-Regular;
    text-align: left;
}

.ProcessStep-Container:hover .ProcessStep-Title {
    color: var(--color-apprise-orange);
}

.ProcessStep-Arrow {
    position: relative;
    top: 8px;
}

.ProcessStep-Description {
    width: 50%;
    font-size: 18px;
    font-family: SpaceGrotesk-Regular;
    text-align: left;
    margin: 50px auto 40px;
}

.ProcessStep-Horizontal-Rule {
    margin: 20px 75px 20px;
    border-top: 1px solid var(--color-apprise-orange);
}

.ReactCollapse--collapse {
    transition: height 150ms;
}

@keyframes rotate-arrow {
    0% {
        transform: rotateZ(180deg);
    }
    100% {
        transform: rotateZ(0deg);
    }
}

.Rotate-Arrow {
    animation-duration: 0.4s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-name: rotate-arrow;
}

@media only screen and (max-width: 600px) {
    .ProcessStep-Container {
        width: 100%;
    }

    .ProcessStep-Title-Container {
        margin-top: 20px;
        margin-left: 30px;
    }

    .ProcessStep-Description {
        width: 75%;
    }

    .ProcessStep-Horizontal-Rule {
        margin: 20px 30px 20px;
    }

    .ProcessStep-Arrow {
        left: 56px;
    }
}
