.Intro-Container {
    width: 500px;
    height: 250px;
    background: rgba(255, 252,238, 0.9);
}

.Intro-Text {
    font-size: 20px;
    font-family: SpaceGrotesk-Regular;
    text-align: left;
    padding: 70px 50px 50px;
}

@media only screen and (max-width: 768px) {
    .Intro-Container {
        width: 400px;
        height: 260px;
    }

    .Intro-Text {
        font-size: 20px;
        padding: 40px 30px 30px;
    }
}

@media only screen and (max-width: 600px) {
    .Intro-Container {
        width: 280px;
        height: 365px;
    }

    .Intro-Text {
        font-size: 20px;
        padding: 40px 30px 30px;
    }
}
