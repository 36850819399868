.Process-Container {
    width: 1000px;
    height: 1000px;
    background: var(--color-apprise-gradient-text);
}

.Process-Title {
    width: 400px;
    font-size: 65px;
    font-family: SpaceGrotesk-Regular;
    text-align: left;
    padding-top: 75px;
    margin-left: 75px;
}

.Arrow-Unfold-Container {
    position: relative;
    left: -120px;
    width: 30px;
    height: 30px;
}

@media only screen and (max-width: 1400px) {

    .Process-Container {
        width: 900px;
    }
}

@media only screen and (max-width: 1024px) {

    .Process-Container {
        width: 850px;
    }
}

@media only screen and (max-width: 900px) {

    .Process-Container {
        width: 675px;
    }
}

@media only screen and (max-width: 768px) {

    .Process-Container {
        width: 555px;
        height: 980px;
    }

    .Process-Title {
        width: 100%;
        font-size: 60px;
        margin-left: 25px;
    }
}

@media only screen and (max-width: 600px) {

    .Process-Container {
        width: 100%;
        height: 950px;
    }

    .Process-Title {
        width: 100%;
        font-size: 35px;
        margin-left: 10px;
    }
}