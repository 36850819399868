.Container {
    margin-top: 170px;
    display: flex;
    flex-flow: column;
    align-content: center;
    width: 100%;
}
.Payoff {
    font-weight: 600;
    font-size: 80px;
    font-family: SpaceGrotesk-Light;
    display: flex;
    align-content: flex-start;
}

@media only screen and (max-width: 768px) {
    .Container {
        margin-top: 130px;
    }
    .Payoff {
        font-size: 65px;
    }
}

@media only screen and (max-width: 600px) {
    .Container {
        margin-top: 130px;
    }
    .Payoff {
        font-size: 40px;
    }
}