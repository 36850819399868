.SoftwareAsAService-Container {
    width: 450px;
    height: 500px;
}

.SoftwareAsAServiceIcon-Container {
    width: 250px;
    height: 250px;
    margin-left: 90px;
    margin-top: 150px;
}

.SoftwareAsAService-Title {
    font-size: 25px;
    font-weight: 500;
    font-family: SpaceGrotesk-Regular;
    text-align: left;
    margin-top: 20px;
    margin-left: 75px;
}

.SoftwareAsAService-Description {
    font-size: 18px;
    font-family: SpaceGrotesk-Regular;
    text-align: left;
    margin: 20px 0px 10px 75px;
}

@media only screen and (max-width: 900px) {
    .SoftwareAsAService-Container {
        width: 90%;
        height: 500px;
        margin-top: 30px;
    }

    .SoftwareAsAServiceIcon-Container {
        width: 300px;
        height: 300px;
        margin-left: 150px;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .SoftwareAsAService-Container {
        width: 90%;
        height: 500px;
        margin-top: 30px;
    }

    .SoftwareAsAServiceIcon-Container {
        width: 300px;
        height: 300px;
        margin-left: 100px;
        margin-top: 20px;
    }

    .SoftwareAsAService-Title {
        margin-left: 30px;
        width: 100%;
    }

    .SoftwareAsAService-Description {
        margin: 20px 0px 10px 30px;
    }
}

@media only screen and (max-width: 600px) {
    .SoftwareAsAService-Container {
        width: 90%;
        height: 500px;
        margin-top: 30px;
    }

    .SoftwareAsAServiceIcon-Container {
        width: 250px;
        height: 250px;
        margin-left: 50px;
        margin-top: 20px;
    }

    .SoftwareAsAService-Title {
        margin-left: 10px;
        width: 100%;
    }

    .SoftwareAsAService-Description {
        margin: 20px 0px 10px 10px;
    }
}