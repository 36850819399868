.Services-Container {
    width: 1000px;
    height: 1200px;
    background: var(--color-apprise-gradient-text);
}

.Services-Row {
    display: flex;
}

.Services-Column {
    flex: 50%;
}

.Services-Title {
    width: 400px;
    font-size: 65px;
    font-family: SpaceGrotesk-Regular;
    text-align: left;
    padding-top: 75px;
    margin-left: 75px;
}

@media only screen and (max-width: 1400px) {
    .Services-Container {
        width: 900px;
    }
}

@media only screen and (max-width: 1024px) {
    .Services-Container {
        width: 850px;
    }
}

@media only screen and (max-width: 900px) {
    .Services-Container {
        width: 675px;
        height: 1850px;
    }

    .Services-Row {
        flex-direction: column;
    }

    .Services-Column {
        flex: 100%;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {

    .Services-Container {
        width: 555px;
        height: 1850px;
    }

    .Services-Row {
        flex-direction: column;
    }

    .Services-Column {
        flex: 100%;
        width: 100%;
    }

    .Services-Title {
        font-size: 60px;
        margin-left: 20px;
    }
}

@media only screen and (max-width: 600px) {

    .Services-Container {
        width: 100%;
        height: 1750px;
    }

    .Services-Row {
        flex-direction: column;
    }

    .Services-Column {
        flex: 100%;
        width: 100%;
    }

    .Services-Title {
        font-size: 35px;
        margin-left: 10px;
    }
}