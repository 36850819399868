.Client-Container {
    padding-left: 30px;
}

.Logo-Container {
    width: 170px;
    position: relative;
}

.Client-Name {
    font-size: 16px;
    font-weight: 600;
    font-family: SpaceGrotesk-Regular;
    text-align: left;
}

.Client-Tags {
    font-size: 16px;
    font-family: SpaceGrotesk-Regular;
    text-align: left;
    color: var(--color-apprise-grey-dark);
}