body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'SpaceGrotesk-Bold';
  src: local('SpaceGrotesk-Bold'),
  url(assets/fonts/SpaceGrotesk-Bold.woff2) format('woff2'),
  url(assets/fonts/SpaceGrotesk-Bold.woff) format('woff');
}

@font-face {
  font-family: 'SpaceGrotesk-Light';
  src: local('SpaceGrotesk-Light'),
  url(assets/fonts/SpaceGrotesk-Light.woff2) format('woff2'),
  url(assets/fonts/SpaceGrotesk-Light.woff) format('woff');
}

@font-face {
  font-family: 'SpaceGrotesk-Medium';
  src: local('SpaceGrotesk-Medium'),
  url(assets/fonts/SpaceGrotesk-Medium.woff2) format('woff2'),
  url(assets/fonts/SpaceGrotesk-Medium.woff) format('woff');
}

@font-face {
  font-family: 'SpaceGrotesk-Regular';
  src: local('SpaceGrotesk-Regular'),
  url(assets/fonts/SpaceGrotesk-Regular.woff2) format('woff2'),
  url(assets/fonts/SpaceGrotesk-Regular.woff) format('woff');
}

@font-face {
  font-family: 'SpaceGrotesk-SemiBold';
  src: local('SpaceGrotesk-SemiBold'),
  url(assets/fonts/SpaceGrotesk-SemiBold.woff2) format('woff2'),
  url(assets/fonts/SpaceGrotesk-SemiBold.woff) format('woff');
}
