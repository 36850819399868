.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    font-family: SpaceGrotesk-Regular;
    text-align: left;
    cursor: pointer;
}

.NavigationItem a {
    color: var(--color-apprise-black);
    text-decoration: none;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:active,
.NavigationItem a.active {
    text-decoration-line: underline;
    text-decoration-color: var(--color-apprise-red);
    text-decoration-thickness: 4px;
    text-underline-offset: -4px;
    text-decoration-skip: none;
    text-decoration-skip-ink: none;
}

.NavigationItem a:hover {
    text-decoration-line: underline;
    text-decoration-color: var(--color-apprise-orange);
    text-decoration-thickness: 4px;
    text-underline-offset: -4px;
    text-decoration-skip: none;
    text-decoration-skip-ink: none;
}
