.LanguageSelector {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.LanguageSelector-Button {
    border: none;
    background-color: transparent;
    height: 20px;
    color: rgb(170, 170, 170);
    cursor: pointer;
}

.LanguageSelector-Button:disabled {
    color: var(--color-apprise-black);
}

.LanguageSelector-Button:hover:enabled {
    color: var(--color-apprise-orange);
}

.LanguageSelector-Divider {
    color: rgb(180, 180, 180);
}