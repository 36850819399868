.Contact-Container {
    width: 100%;
    min-height: 100vh;
    background: var(--color-apprise-orange);
}

.Contact-Carpe-Diem {
    font-size: 140px;
    font-family: SpaceGrotesk-Regular;
    padding-top: 20px;
    padding-right: 125px;
    color: var(--color-apprise-grey-trans);
}

@media only screen and (max-width: 900px) {

    .Contact-Carpe-Diem {
        font-size: 120px;
        padding-right: 110px;
    }
}

@media only screen and (max-width: 768px) {

    .Contact-Carpe-Diem {
        font-size: 93px;
        padding-right: 110px;
    }
}

@media only screen and (max-width: 600px) {

    .Contact-Carpe-Diem {
        font-size: 53px;
        padding-right: 40px;
    }
}

@media only screen and (max-width: 415px) {
    .Contact-Container {
        min-height: 150vh;
    }
}