.Sun-Container {
    align-items: center;
    margin: auto;
}

.Sun {
    height: 600px;
    max-height: 600px;
    max-width: 600px;
    width: 600px;
    background-color: var(--color-apprise-yellow-trans);
    border-radius: 50%;
    align-items: center;
    display: inline-block;
}

.SunALife {
    pointer-events: none;
    will-change: transform;
}

@media only screen and (max-width: 900px) {
    .Sun {
        height: 440px;
        max-height: 440px;
        max-width: 440px;
        width: 440px;
    }
}

@media only screen and (max-width: 768px) {
    .Sun {
        height: 400px;
        max-height: 400px;
        max-width: 400px;
        width: 400px;
    }
}

@media only screen and (max-width: 600px) {
    .Sun {
        height: 320px;
        max-height: 320px;
        max-width: 320px;
        width: 320px;
    }
}

@keyframes rise {
    0% {
        transform: translateY(115%);
        background-color: var(--color-apprise-red-trans);
    }
    100% {
        transform: translateY(0px);
        background-color: var(--color-apprise-yellow-trans);
    }
}

.rise {
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-name: rise;
}