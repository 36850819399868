.Contact-Overlay-Container {
    width: 100%;
    min-height: 100vh;
    background: transparent;
}

.Contact-Overlay-Description {
    font-size: 32px;
    font-family: SpaceGrotesk-Regular;
    font-weight: bolder;
    width: 30%;
    text-align: left;
    padding-left: 20%;
    padding-top: 200px;
}

.Contact-Overlay-Contact {
    font-size: 32px;
    font-family: SpaceGrotesk-Regular;
    width: 25%;
    text-align: left;
    padding-top: 30px;
    padding-left: 50%;
}

.Contact-Anchor-Tag {
    text-decoration: none;
    color: black;
}

.Contact-Anchor-Tag:hover {
    text-decoration-line: underline;
    text-decoration-color: white;
    text-decoration-thickness: 4px;
    text-underline-offset: -4px;
    text-decoration-skip: none;
    text-decoration-skip-ink: none;
}

.Contact-Overlay-Footer-Container {
    position: relative;
    top: calc(100vh - 610px);
}

.Contact-Overlay-Footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 80px;
    padding-right: 80px;
}

.ContactOverlay-Footer-Item {
    font-size: 20px;
    font-family: SpaceGrotesk-Regular;
}

@media only screen and (max-width: 1366px) {
    .Contact-Overlay-Description {
        width: 38%;
        padding-left: 20%;
    }

    .Contact-Overlay-Footer-Container {
        top: calc(100vh - 650px);
    }
}

@media only screen and (max-width: 1200px) {

    .Contact-Overlay-Description {
        width: 50%;
        padding-left: 8%;
    }

    .Contact-Overlay-Contact {
        width: 38%;
        padding-top: 40px;
    }

    .Contact-Overlay-Footer {
        padding-left: 50px;
        padding-right: 50px;
    }

    .Contact-Overlay-Footer-Container {
        top: calc(100vh - 620px);
    }
}

@media only screen and (max-width: 1024px) {

    .Contact-Overlay-Description {
        width: 50%;
        padding-left: 8%;
    }

    .Contact-Overlay-Contact {
        width: 38%;
        padding-top: 40px;
    }

    .Contact-Overlay-Footer {
        padding-left: 50px;
        padding-right: 50px;
    }

    .Contact-Overlay-Footer-Container {
        top: calc(100vh - 650px);
    }
}

@media only screen and (max-width: 900px) {

    .Contact-Overlay-Description {
        width: 50%;
        padding-left: 8%;
    }

    .Contact-Overlay-Contact {
        width: 35%;
        padding-left: 45%;
        padding-top: 40px;
    }

    .Contact-Overlay-Footer {
        padding-left: 50px;
        padding-right: 50px;
    }

    .Contact-Overlay-Footer-Container {
        top: calc(100vh - 700px);
    }
}

@media only screen and (max-width: 768px) {

    .Contact-Overlay-Description {
        font-size: 25px;
        width: 52%;
        padding-left: 8%;
    }

    .Contact-Overlay-Contact {
        font-size: 25px;
        width: 40%;
        padding-left: 40%;
        padding-top: 40px;
    }

    .Contact-Overlay-Footer {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media only screen and (max-width: 600px) {

    .Contact-Overlay-Description {
        font-size: 25px;
        width: 90%;
        padding-left: 20px;
        padding-top: 130px;
    }

    .Contact-Overlay-Contact {
        font-size: 25px;
        width: 80%;
        padding-left: 20px;
        padding-top: 40px;
    }

    .Contact-Overlay-Footer-Container {
        padding-top: 17vh;
    }

    .Contact-Overlay-Footer {
        padding-left: 20px;
        padding-right: 20px;
    }

    .Contact-Overlay-Footer-Container {
        top: calc(100vh - 650px);
    }
}

@media only screen and (max-width: 415px) {
    .Contact-Overlay-Container {
        min-height: 150vh;
    }

    .Contact-Overlay-Footer-Container {
        top: calc(150vh - 650px);
    }
}