.NavigationItems {
    margin-top: 200px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {
    .NavigationItems {
        display: none;
    }
}
