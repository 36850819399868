.Clients-Container {
    width: 1000px;
    height: 600px;
    background: var(--color-apprise-gradient-text);
}

.Clients-Title {
    width: 50%;
    font-size: 65px;
    font-family: SpaceGrotesk-Regular;
    text-align: left;
    padding-top: 75px;
    margin-left: 75px;
    padding-bottom: 30px;
}

.Clients-carousel-button-group {
    position: relative;
    padding-top: 20px;
    right: -380px;
}

.Clients-Arrow-Left-Container {
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.Clients-Arrow-Right-Container {
    position: relative;
    top: -1px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

button {
    background-color: transparent;
    border: transparent;
}

button:focus {
    outline: none;
}

@media only screen and (max-width: 1400px) {
    .Clients-Container {
        width: 900px;
    }

    .Clients-carousel-button-group {
        position: absolute;
        right: 500px;
    }
}


@media only screen and (max-width: 1200px) {
    .Clients-Container {
        width: 900px;
    }

    .Clients-carousel-button-group {
        position: absolute;
        right: 300px;
    }
}

@media only screen and (max-width: 1024px) {
    .Clients-Container {
        width: 850px;
    }

    .Clients-carousel-button-group {
        position: absolute;
        right: 280px;
    }
}

@media only screen and (max-width: 900px) {
    .Clients-Container {
        width: 675px;
    }

    .Clients-carousel-button-group {
        position: absolute;
        right: 220px;
    }
}

@media only screen and (max-width: 768px) {
    .Clients-Container {
        width: 555px;
        height: 540px;
    }

    .Clients-Title {
        width: 50%;
        font-size: 60px;
        margin-left: 20px;

    }

    .Clients-carousel-button-group {
        position: absolute;
        right: 250px;
    }
}

@media only screen and (max-width: 600px) {
    .Clients-Container {
        width: 100%;
        height: 460px;
    }

    .Clients-Title {
        width: 100%;
        font-size: 35px;
        margin-left: 10px;

    }

    .Clients-carousel-button-group {
        position: absolute;
        right: 40px;
    }
}