.Header {
    height: 85px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.HeaderBar nav {
    height: 100%;
}

.HeaderName {
    margin-top: 25px;
    font-size: 45px;
    font-family: SpaceGrotesk-Bold;
    align-items: center;
    width: 100%;
}