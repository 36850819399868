.MobileApps-Container {
    width: 450px;
    height: 500px;
    margin-top: 90px;
}

.MobileAppIcon-Container {
    width: 250px;
    height: 250px;
    margin-left: 90px;
    margin-top: 70px;
}

.MobileApps-Title {
    font-size: 25px;
    font-weight: 500;
    font-family: SpaceGrotesk-Regular;
    text-align: left;
    margin-top: 20px;
}

.MobileApps-Description {
    font-size: 18px;
    font-family: SpaceGrotesk-Regular;
    text-align: left;
    margin: 20px 80px 10px 0px;
}

@media only screen and (max-width: 1024px) {
    .MobileApps-Container {
        width: 420px;
    }

    .MobileAppIcon-Container {
        margin-left: 70px;
    }
}

@media only screen and (max-width: 900px) {
    .MobileApps-Container {
        width: 90%;
        height: 500px;
        margin-top: 30px;
    }

    .MobileAppIcon-Container {
        width: 300px;
        height: 300px;
        margin-left: 150px;
        margin-top: 20px;
    }

    .MobileApps-Title {
        margin-left: 65px;
    }

    .MobileApps-Description {
        margin-left: 65px;
        margin-right: 0px;
    }
}

@media only screen and (max-width: 768px) {
    .MobileApps-Container {
        width: 90%;
        height: 500px;
        margin-top: 30px;
    }

    .MobileAppIcon-Container {
        width: 300px;
        height: 300px;
        margin-left: 100px;
        margin-top: 20px;
    }

    .MobileApps-Title {
        margin-left: 30px;
        width: 100%;
    }

    .MobileApps-Description {
        margin: 20px 0px 10px 30px;
    }
}

@media only screen and (max-width: 600px) {
    .MobileApps-Container {
        width: 90%;
        height: 500px;
        margin-top: 30px;
    }

    .MobileAppIcon-Container {
        width: 250px;
        height: 250px;
        margin-left: 50px;
        margin-top: 20px;
    }

    .MobileApps-Title {
        margin-left: 10px;
        width: 100%;
    }

    .MobileApps-Description {
        margin: 20px 0px 10px 10px;
    }
}