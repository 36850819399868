.Content {
    margin-top: 72px;
}

.Layout-Container {
    position: relative;
    z-index: 1;
    width: 100%;
    min-height: 5575px;
    overflow-x: hidden;
}

.Layout-Container-background {
    background: var(--color-apprise-gradient-background);
}

.Layout-Header-Container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
}

.Layout-Content-Container {
    display: flex;
    flex-direction: row;
}

.Layout-Home-Container {
    position: absolute;
    left: 15vw;
    z-index: 10;
    width: 100%;
}

.Layout-Contact-Container {
    top: 4500px;
    position: absolute;
    z-index: 4;
    width: 100%;
}

.Layout-Overlay-Contact-Container {
    top: 4500px;
    position: absolute;
    z-index: 6;
    width: 100%;
}

.Layout-Sidebar-Container {
    width: 200px;
    position: fixed;
    top: 50px;
    right: 30px;
    z-index: 30;
}

.Layout-Background-Container {
    position: fixed;
    z-index: 5;
    top: 55px;
    align-items: center;
    width: 100%;
}

.background-rise {
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-name: background-rise;
}

@keyframes background-rise {
    0% {
        background: linear-gradient(to right, #fffdfc, #fff3ef);
    }
    100% {
        background: linear-gradient(to right, #fffefa, #fffced);
    }
}

.content-fade-in {
    animation-duration: 0.35s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-name: content-fade-in;
}

@keyframes content-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1.0;
    }
}


@media only screen and (max-width: 1500px) {
    .Layout-Sidebar-Container {
        right: -30px;
    }
}

@media only screen and (max-width: 1400px) {
    .Layout-Sidebar-Container {
        right: -40px;
    }
}

@media only screen and (max-width: 1200px) {
    .Layout-Sidebar-Container {
        right: -80px;
    }
}

@media only screen and (max-width: 1024px) {
    .Layout-Sidebar-Container {
        right: -90px;
    }

    .Layout-Container {
        height: 6500px;
    }

    .Layout-Contact-Container {
        top: 4850px;
    }

    .Layout-Overlay-Contact-Container {
        top: 4850px;
    }
}

@media only screen and (max-width: 900px) {
    .Layout-Sidebar-Container {
        right: -90px;
    }

    .Layout-Container {
        height: 6500px;
    }

    .Layout-Contact-Container {
        top: 5150px;
    }

    .Layout-Overlay-Contact-Container {
        top: 5150px;
    }
}

@media only screen and (max-width: 768px) {
    .Layout-Sidebar-Container {
        right: -90px;
    }

    .Layout-Container {
        height: 6100px;
    }

    .Layout-Contact-Container {
        top: 5050px;
    }

    .Layout-Overlay-Contact-Container {
        top: 5050px;
    }
}

@media only screen and (max-width: 600px) {
    .Layout-Sidebar-Container {
        width: 40px;
        top: 20px;
        right: 40px;
    }

    .Layout-Container {
        height: 4790px;
    }

    .Layout-Contact-Container {
        top: 4655px;
    }

    .Layout-Overlay-Contact-Container {
        top: 4655px;
    }
}

@media only screen and (max-width: 415px) {
    .Layout-Container {
        height: 4790px;
        min-height: 5500px;
    }
}
