.App {
  text-align: center;
}

:root {
  --color-apprise-black: rgb(0, 0, 0);
  --color-apprise-red: rgb(251, 136, 97);
  --color-apprise-red-trans: rgba(251, 136, 97, 0.9);
  --color-apprise-orange: rgb(253, 187, 99);
  --color-apprise-yellow: rgb(255, 224, 74);
  --color-apprise-yellow-trans: rgba(255, 224, 74, 0.9);
  --color-apprise-grey: rgb(237, 237, 237);
  --color-apprise-grey-trans: rgba(237, 237, 237, 0.5);
  --color-apprise-grey-dark: rgb(137, 137, 137);
  --color-apprise-mist-medium: rgb(255, 252, 237);
  --color-apprise-mist-light: rgb(255, 254, 250);
  --color-apprise-mist-medium-trans: rgba(255, 252, 237, 0.9);
  --color-apprise-mist-light-trans: rgba(255, 254, 250, 0.9);
  --color-apprise-gradient-text: linear-gradient(to right, var(--color-apprise-mist-medium-trans), var(--color-apprise-mist-light-trans));
  --color-apprise-gradient-background: linear-gradient(to right, var(--color-apprise-mist-light), var(--color-apprise-mist-medium));
}




